import React from 'react';
import styled, { keyframes } from 'styled-components'

const wobble = keyframes`
  0% {
    -webkit-transform: translateY(0px) rotate(-0.5deg);
  }

  50% {
    -webkit-transform: translateY(30px) rotate(1deg);
  }

  100% {
    -webkit-transform: translateY(0px) rotate(-0.5deg);
    }
`;

const AnimationWrapper = styled.div`
animation: ${wobble} 3s linear infinite;
`

const animatedImage = (props) => {
  return <AnimationWrapper>{props.children}</AnimationWrapper>
};

export default animatedImage;