import React from 'react';
import styled from 'styled-components';

const ContainerWrapper = styled.div`

padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 1000px;
    margin: 0 auto 100px;
flex-direction: ${props => props.direction}; 
display: flex;
    justify-content: center;
    ${props => props.position === "intro" ? "" : "align-items: center;"}; 
margin: auto;    
${props => props.vh ? "min-height: var(--app-height);" : ""};

.description p{
    padding-bottom: 1em;
}

@media(max-width:512px){
    ${props => props.vh ? "min-height: unset;" : ""};
}

.logo{
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

.logo svg{
 width:80%;
}


@media (min-width:640px){
    padding-right: 2.8125rem;
    padding-left: 2.8125rem;
}



${'' /* & > * {
  flex-grow: 1;
} */}


@media (min-width:1800px){
    max-width: ${props => props.noMargin ? "unset" : "1280px"};
}

@media (max-width:1024px){
    flex-direction: ${props => props.reversed ? "column;" : "column-reverse;"}; 
    .mockupVideo{
    width:80%;
    padding-bottom: 10
    }
}

@media (max-width:512px){

h2:nth-child(3){
  padding-top:2rem;
}
}

@media (max-width:1024px){

.description{
    width:100%;
    padding-bottom: 100px;
}
}

@media (min-width:768px){
.fontBigger p{
   font-size: 36px;
}
}

@media (min-width:1024px){
    padding-right: .9375rem;
    padding-left: .9375rem;



.list{
    width:30%;
}

.description{
    width:50%;
    ${props => props.direction === "row-reverse" ? "padding-right: 5rem;" : "padding-left: 5rem;"};
}

.image,.mockupVideo{
    width:50%;
}
}


.list>ul>li:not(:last-of-type) {
    margin-bottom: 100px;
}

.list ul li strong {
    color: #7d7d7d;
    margin-bottom: .4em;
    ${'' /* font-family: "Fjalla One"; */}
    font-family:"montserrat", sans-serif;
    line-height: 2;
}

.list ul li a.reveal span {
    display: block;
    margin-bottom: -6px;
    overflow: hidden;
    position: relative;
}
`
const Container = (props) => {

    return <ContainerWrapper noBottom={props.noBottom} noTop={props.noTop} direction={props.direction} block={props.block} center={props.center} reversed={props.reversed} noMargin={props.noMargin} vh={props.vh} position={props.position}>
        {props.children}
    </ContainerWrapper>
};

export default Container;