import React from "react"
import styled from 'styled-components';
import Layout from "../layouts";
import Hover from "../components/hover";
import Footer from "../components/footer";
import VideoMockup from "../components/videoMockup";
import Container from "../components/./container";
import BackgroundImage from "../components/image";
import AnimatedText from '../components/animatedText';
import ArrowDown from "../components/arrowDown";
import AnimatedImage from "../components/animatedImage";
import Seo from "../components/seo"

const Wrapper = styled.div`
.flux > div {
    padding-top: 100px;
}

.flux > div:first-child {
    padding-top: 20vh;
    padding-bottom: 5vh;
}

.flux > div:nth-child(2){
    padding-top: 0;
}

h1{
    text-align:left;
    text-transform: uppercase;
    letter-spacing: .5rem;
    font-size: 5rem;
    padding-bottom:2rem;
}

.wecreate2,
.wecreate3{
    position:absolute;
    top:0;
}

@media(max-width:512px){
    h1{
    letter-spacing: .3rem;
    font-size: 2.5rem;
}
}
`


// markup
const VirtualShowroom = () => {

    return (
        <>
            <Layout>
                <Seo title="Virtual Showroom - Vertual" />
                <Hover type="">
                    <main data-scroll data-scroll-id="update">
                        <ArrowDown />
                        <Wrapper>
                            <div className="flux">
                                <Container block position="intro" reversed direction="column">
                                    <AnimatedText tag="h1">Virtual Showroom</AnimatedText>
                                    <AnimatedText tag="p">
                                        Virtual Showrooms are immersive spaces that allow visitors to interact and engage with products, environments, medias and
                                        easily connect with a product specialist, a digital environment where to buy, sell and collaborate online.
                                    </AnimatedText>
                                </Container>
                                <VideoMockup video={"virtualshowroom1"} fullpage />
                                <Container vh>
                                    <div className="mockupVideo">
                                        <VideoMockup video={"iphonevirtualshowroom"} flexend />
                                    </div>
                                    <div className="description">
                                        <AnimatedText tag="h2">Exclusive Virtual Experiences</AnimatedText>
                                        <AnimatedText tag="p">The platform can manage unlimited design customization, allowing Brands to show personalized collections and different medias to multiple users at the same time, also inviting special customers to their own private live shopping experience.</AnimatedText>
                                    </div>
                                </Container>
                                <VideoMockup video={"virtualshowroom2"} />
                                <Container vh reversed>
                                    <div className="description">
                                        <AnimatedText tag="h2">Optimized for any device</AnimatedText>
                                        <AnimatedText tag="p">Buyers can visit your interactive virtual showroom customized with dedicated product catalogue, media assortment and directly interact with a sales representative in real time.</AnimatedText>
                                    </div>
                                    <div className="mockupVideo">
                                        <AnimatedImage><BackgroundImage filename={"virtualshowroom_dino.jpg"} /></AnimatedImage>
                                    </div>
                                </Container>
                                <VideoMockup video={"virtualshowroom3"} fullpage />
                                <Container vh>
                                    <div className="mockupVideo">
                                        <VideoMockup video={"iphonevirtualshowroom2"} flexend />
                                    </div>
                                    <div className="description">
                                        <AnimatedText tag="h2">Effortless Embedding and API Integration</AnimatedText>
                                        <AnimatedText tag="p">
                                            The Virtual Showroom can fully integrate any existing order intake system, video and voice chat platforms, live streaming, one-on-one meetings and group video conferences.
                                        </AnimatedText>
                                    </div>
                                </Container>
                                <Footer page="digitalevents" />
                            </div>
                        </Wrapper>
                    </main>
                </Hover>
            </Layout>
        </>
    )
}

export default VirtualShowroom


